import { Box, Typography, Link } from "@mui/material";
import React from 'react';

function Experience()
{
    return (
      <Box padding='40px'>
          <Typography
              variant="h3"
              paddingBottom='20px'>
                Experience
          </Typography> 

          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              <Link href="https://www.shadow.tech" target="_blank" underline="hover" style={{color: '#972C2D'}}>
                <span style={{color: '#972C2D'}}>Shadow</span>
              </Link>
              {' | Software engineer intern'}
          </Typography>
          <Typography>
            September 2023 - January 2024
          </Typography>
          <Typography
            paddingBottom='20px'>
            • Adapted the existing ShadowUSB service for desktop to function on Android client, enabling USB devices connected to an Android device to be used with Shadow PC in the cloud<br/>
• Participated in an agile development using SCRUM <br/>
• Technologies: Kotlin, C++, CMake, Djinni
          </Typography>

          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              <Link href="https://www.sharelock.co/" target="_blank" underline="hover" style={{color: '#972C2D'}}>
                <span style={{color: '#972C2D'}}>SHARELOCK</span>
              </Link>
              {' | Full-stack developer intern'}
          </Typography>
          <Typography>
            May 2022 - July 2022
          </Typography>
          <Typography
            paddingBottom='20px'>
            • Contributed to the development and testing of features for Sharelock's website and mobile app <br/>
• Developed a statistical dashboard for an internal tool, focusing on the customer's data analysis and visualization <br/>
• Technologies: JavaScript, TypeScript, React native, React, Strapi
          </Typography>

          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              <span style={{color: '#972C2D'}}>CANADIAN STARTUP </span>
              | Flutter developer intern
          </Typography>
          <Typography>
            June 2020 - September 2020
          </Typography>
          <Typography
            paddingBottom='20px'>
            •  Development of an application: GoodSprout used to remind people to water their plants <br/>
            • Technologies: Flutter, Dart, Firebase, SQLite
          </Typography>
      </Box>
    );
}

export default Experience;