import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

const SchoolRole = ({ positionName, keySkills, dates }) => {
  const isSmallScreen = useMediaQuery('(max-width:1270px)');
  
  return (
    <Box 
      display="flex" 
      flexDirection={isSmallScreen ? 'column' : 'row'} 
      justifyContent="space-between" 
      alignItems={isSmallScreen ? 'flex-start' : 'center'}
      paddingRight={{ xs: '10px', sm: '20px', md: '40px' }}
    >
      <Box display="flex" flexDirection="row">
        <Typography 
          style={{ fontWeight: "bold", paddingRight: '10px' }}
        >
          {positionName}
        </Typography>
        <Typography 
          style={{ color: '#972C2D', fontWeight: "bold" }}
        >
          {keySkills}
        </Typography>
      </Box>
      
      <Typography 
        style={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }} // Smaller font on small screens
      >
        {dates}
      </Typography>
    </Box>
  );
};

export default SchoolRole;
