import { Box, Typography } from "@mui/material";
import React from 'react';
import SchoolRole from "../../components/SchoolRole";

function Education()
{
    return (
      <Box paddingTop='40px' paddingLeft='40px'>
          <Typography
            variant="h3"
            paddingBottom='20px'>
              Education
          </Typography>
          <Typography
            variant="h5"
            style={{fontWeight:"bold"}}>
              EPITA
          </Typography>
          <Typography>
            September 2020 - PRESENT
          </Typography>
          <Typography style={{paddingBottom:'10px'}}>
            • school of Engineering and computer science <br/>
            • currently in final year of engineering cycle (Master 2 equivalent) <br/>
            • major MTI specialising in mobile development (iOS)
          </Typography>

          {/* Teaching assistant (ACU) */}
          <SchoolRole 
            positionName="Teaching Assistant (ACU)" 
            keySkills="C, Shell, SQL" 
            dates="SEPTEMBER 2024 - PRESENT" 
          />

            {/* Teaching assistant (YAKA) */}
          <SchoolRole
            positionName="Teaching Assistant (YAKA)"
            keySkills="Java, C++"
            dates="SEPTEMBER 2023 - JULY 2024"
            />

            {/* Teaching assistant (ASM) */}
            <SchoolRole
            positionName="Teaching Assistant (ASM)"
            keySkills="C, Rust"
            dates="SEPTEMBER 2022 - JULY 2023"
            />

            {/* Google Developer Student Club EPITA */}
            <SchoolRole
            positionName="Mobile Dev Lead (GDSC EPITA)"
            keySkills="Flutter, Dart"
            dates="SEPTEMBER 2022 - JULY 2023"
            />
      </Box>
    );
}

export default Education;