import { Grid, Box, Typography,Container } from "@mui/material";
import React from 'react';
import theme from "../../styles/theme";
import { useMediaQuery } from 'react-responsive';


function Hero()
{
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });
 
    return (
        <>
        <section 
            id = "hero"
            theme={theme}
            style={{
                      display: 'flex',
                      height: '100%',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems:'center',
                      justifyItems: 'center',
                      textAlign: 'center',
                      flexGrow: 1}}
                     >
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} >
          {
            !isMobile ?
            <Grid item xs={4}
                     sm={3}
                     md={6}
                     color="red">
                      <img src="BarunkaCoffee.png" alt="hero" height="800px"/>
            </Grid>
          : 
            <Container/>
          }
          <Grid item xs={4}
                     sm={4}
                     md={6}
                     sx={{display:'flex',
                          justifyContent:'center',
                          alignItems:'center',
                          justifyItems:'center'}}>
              <Container>
                <Box textAlign="left">
                  <Box flexDirection="row">
                    <Typography
                      variant="h2"
                      style={{fontWeight:"bold"}}
                      paddingTop={isMobile ? '50px' : '5px'}>
                        Barbora Plašovská
                    </Typography>
                  </Box>
                  <Typography
                    variant="h5">
                    iOS engineer intern <br/>
                    Paris, France
                  </Typography>
                </Box>
              </Container>
          </Grid>
          {
            isMobile ?
            <Grid item xs={4}
                     sm={4}
                     md={6}
                     color="red">
              <img src="BarunkaCoffee.png" alt="hero" height="400px"/>
            </Grid>
            : 
            <Container/>
          }
        </Grid>
      
    </section>
    </>
    );
}

export default Hero;
